import Images from '../../utils/ImageConst';
import { Link } from 'react-router-dom';

const GetStarted = () => {
    return (
        <div className="wrapper getStartedWrapper">
            <div className="container m-0 p-0">
                <div className="main-container">
                    <div className="pt-4 px-3">
                        <div className="d-flex justify-content-between">
                            <div className="home-screen-text">
                                <p className="text-muted mb-0">Welcome to</p>
                                <h3 className="mb-0">LITTLE ITALY</h3>
                                <p className="text-muted mb-1">Sadashiv Nagar</p>
                                <div className="prod-rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                </div>
                            </div>
                            <div className="share-btn text-align-right">
                                <img className="img-fluid" src={Images.share} alt="share" />
                            </div>
                        </div>
                        <div className="bottom-btn-nav-cont px-0 py-3">
                            <div className="d-flex justify-content-around">
                                <div className="home-nav-btns">
                                    <Link to="/home">
                                        <button>Menu</button>
                                    </Link>
                                </div>
                                <div className="home-nav-btns">
                                    <Link to="/paybill">
                                        <button>Pay Bill</button>
                                    </Link>
                                </div>
                                <div className="home-nav-btns">
                                    <button>Waiter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetStarted;