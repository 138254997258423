import Images from '../../utils/ImageConst';
import { useEffect, useState } from 'react';

const PayBill = (props) => {

    const [billAmount, setBillAmount] = useState('')

    let billAmountHanlder = (e) => {
        let temp = `${billAmount}${e}`
        setBillAmount(temp)
    }

    let billBackspaceHandler = () => {
        let temp = billAmount.slice(0,-1)
        setBillAmount(temp)        
    }

    


    return (
        <div className="wrapper" style={{backgroundColor: '#fff'}}>
            <div className="container m-0 p-0">
                <div className="py-0 m-0 pay-bill-main-cont">
                    <div className="d-flex py-3 w-100 justify-content-between align-items-center payBillStatusBar">
                        <div className="go-back">
                            <i className="fas fa-chevron-left"></i>
                        </div>
                        <div className="share-btn"><img src={Images.shareDark} alt="anImg" />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-3 px-1 pt-5">
                        <div className="bill-amount-cont">
                            <p className="bill-amt"><img className="img-fluid" src={Images.payBill_svg} /> &nbsp;Bill Amount</p>
                            <div className="input-group pay-bill-Inp">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="pay-bill-input">₹</span>
                                </div>
                                <input type="text" id="bilAmountInput" 
                                className="form-control" placeholder="Amount"
                                    aria-label="Username" aria-describedby="pay-bill-input"
                                     value={billAmount} disabled/>
                            </div>
                            <p className="text-muted mb-0">Enter Amount on your bill</p>
                        </div>
                        <div className="pay-bill-img">
                            <img className="img-fluid" src={Images.payBillIcon} alt="anImg" />
                        </div>
                    </div>

                    <div className="bold-hr-sep my-3"></div>

                    <div className="keyboard-cont">
                        <div className="key-brd-cont-inner">
                            <div className="key-brd-flex">
                                <button onClick={()=>billAmountHanlder(1)}>1</button>
                                <button onClick={()=>billAmountHanlder(2)}>2</button>
                                <button onClick={()=>billAmountHanlder(3)}>3</button>
                            </div>
                            <div className="key-brd-flex">
                                <button onClick={()=>billAmountHanlder(4)}>4</button>
                                <button onClick={()=>billAmountHanlder(5)}>5</button>
                                <button onClick={()=>billAmountHanlder(6)}>6</button>
                            </div>
                            <div className="key-brd-flex">
                                <button onClick={()=>billAmountHanlder(7)}>7</button>
                                <button onClick={()=>billAmountHanlder(8)}>8</button>
                                <button onClick={()=>billAmountHanlder(9)}>9</button>
                            </div>
                            <div className="key-brd-flex">
                                <button onClick={()=>billAmountHanlder(0)}>0</button>
                                <button  onClick={()=>billAmountHanlder('.')}>.</button>
                                <button onClick={billBackspaceHandler}><i className="fas fa-chevron-left"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="proceed-btn-cont">
                        <button disabled={billAmount > 0 ? false : true} >Proceed to pay</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayBill;