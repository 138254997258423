import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Bill27 from "../screens/bill";
import ApplyCoupon39 from "../screens/coupon";
import GetStarted from "../screens/getStarted";
import Home from "../screens/home";
import MyOrder17 from "../screens/myOrder/components/myOrder17";
import MyOrder22 from "../screens/myOrder/components/myOrder22";
import MyOrder24 from "../screens/myOrder/components/myOrder24";
import MyOrder37 from "../screens/myOrder/components/myOrder37";
import PayBill from "../screens/payBill";
import PaymentOption from "../screens/paymentOptions";
import NewUpiID from "../screens/paymentOptions/components/newUpi";
import PayAtStore31 from "../screens/paymentOptions/components/payAtStore31";
import PayAtStore32 from "../screens/paymentOptions/components/payAtStore32";
import PayWithCard from "../screens/paymentOptions/components/payWithCard";
import ProdDrink from "../screens/productDetails/components/prodDrink";
import ProdToppings from "../screens/productDetails/components/prodToppings";
import ProdDetails from "../screens/productDetails/components/productDetails";

import '../assets/css';
import MyOrder38 from "../screens/myOrder/components/myOrder38";
import ProdDetails2 from "../screens/productDetails/components/product-details2";
import ScrollToTop from "./components/scrollToTop";

function Navigation() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Switch>
                    <Route exact path="/" component={GetStarted} />
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/paybill" component={PayBill} />
                    <Route exact path="/prod-details" component={ProdDetails} />
                    <Route exact path="/prod-details2" component={ProdDetails2} />
                    <Route exact path="/prod-details-toppings" component={ProdToppings} />
                    <Route exact path="/prod-details-drink" component={ProdDrink} />
                    <Route exact path="/my-order17" component={MyOrder17} />
                    <Route exact path="/my-order22" component={MyOrder22} />
                    <Route exact path="/my-order24" component={MyOrder24} />
                    <Route exact path="/my-order37" component={MyOrder37} />
                    <Route exact path="/my-order38" component={MyOrder38} />
                    <Route exact path="/bill27" component={Bill27} />
                    <Route exact path="/payment-options28" component={PaymentOption} />
                    <Route exact path="/pay-with-card29" component={PayWithCard} />
                    <Route exact path="/new-upi30" component={NewUpiID} />
                    <Route exact path="/pay-at-store31" component={PayAtStore31} />
                    <Route exact path="/pay-at-store32" component={PayAtStore32} />
                    <Route exact path="/apply-coupon" component={ApplyCoupon39} />
                    <Redirect from='*' to='/' />
                </Switch>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default Navigation;