import { ReactComponent as Food } from './../assets/icons/food(1).svg';
import { ReactComponent as Lunch } from './../assets/icons/lunch.svg';

const SvgFood = (props) => {
    return (
        <>
            <Food fill={props.color} />
        </>
    )
}
const SvgLunch = (props) => {
    return (
        <>
            <Lunch fill={props.color} />
        </>
    )
}

export {SvgFood, SvgLunch}