import Images from '../../utils/ImageConst';
import { useEffect, useState } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import HeaderScrollPersistantAnimation from './components/HeaderScrollPersistantAnimation';

const Home = () => {

    const [activeStory, setActiveStory] = useState(0)
    const [itemQuantity, setItemQuantity] = useState({})
    const [headerScrollAnimation, setHeaderScrollAnimation] = useState('none')

    let cardArr = [1, 2, 3, 4, 5, 6]

    useEffect(() => {
        var stickySidebar = $('.fixed-header').offset().top;
        $(window).scroll(function () {
            if ($(window).scrollTop() > stickySidebar) {
                $('.fixed-header').addClass('fix-header-active');
                $('.toggle-search-input').removeClass('none');
                $('.card-cont-home').addClass('margintop')
                
                // setHeaderScrollAnimation('block');
            }
            else {
                $('.fixed-header').removeClass('fix-header-active');
                $('.toggle-search-input').addClass('none');
                $('.card-cont-home').removeClass('margintop')
                // setHeaderScrollAnimation('none');
            }
        });
    }, [])

    


    let quantityHandler = (id, type, itemQ) => {
        let qunatityObj = itemQ;
        if (type == 'dec') {
            qunatityObj[id] = itemQ[id] - 1
        } else if (type == 'inc') {
            qunatityObj[id] = itemQ[id] + 1
        } else if (type == 'add') {
            qunatityObj[id] = 1
        }
        setItemQuantity({...qunatityObj})
    }


    return (
        <div className="wrapper">
            <div className="container m-0 p-0">
                <div className="d-flex justify-content-between pt-4 px-3 search-container">
                    <div className="mb-3 pt-0 flex-1-prop">
                        <div className="input-group search-inp">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><img className="img-fluid"
                                    src={Images.search} alt="anImg" /></span>
                            </div>
                            <input type="text" className="form-control" placeholder="" aria-label="Username"
                                aria-describedby="basic-addon1" />
                        </div>
                    </div>
                    <div className="srch-mic  pt-0">
                        <div className="p-0 m-0 d-flex srch-mic-inner justify-content-center align-items-center">
                            <img className="img-fluid" src={Images.mic} alt="anImg" />
                        </div>
                    </div>
                </div>

                <div className="fix-header fixed-header">
                    {/* <!-- scroll story --> */}
                    <div className="container py-2 px-2">
                        <div className="d-flex story-flexbox">
                            {
                                cardArr.map((e, i) => (
                                    <div key={`story${i}`} className="story-item-cont">
                                        <a href="#">
                                            <div
                                                key={`story${i}`}
                                                className={`story-item ${activeStory == i && 'story-active'}`}
                                                onClick={() => setActiveStory(i)}>
                                                <img src={Images.storyImg} alt="anImg" />
                                            </div>
                                            <p className="story-text mb-1">Bestseller</p>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {/* <!-- scroll story ends --> */}

                    {/* <!-- toggle btn cont --> */}
                    <div className="toggle-btns-main-cont container px-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="mx-0 d-flex align-items-center">
                                <div className="toggle-item mx-2">
                                    <label className="form-switch-ios d-flex align-items-center">
                                        <input type="checkbox" />
                                        <i></i> Veg only </label>
                                </div>
                                <div className="toggle-item mx-2">
                                    <label className="form-switch-ios switch-yellow d-flex align-items-center">
                                        <input type="checkbox" />
                                        <i></i> Include Egg </label>
                                </div>
                            </div>
                            <div className="mx-2 toggle-search-input none" onClick={() => window.scroll(0, 0)} >
                                <img style={{ width: '26.22px' }} src={Images.searchCircle} alt="search" />
                            </div>
                        </div>
                    </div>
                    {/* <!-- toggle btn cont ends --> */}
                </div>

                <HeaderScrollPersistantAnimation display={headerScrollAnimation} />

                {/* <!-- main card cont --> */}
                <div className="container mt-3 p-0 main-card-cont card-cont-home">
                    <div className="d-flex px-0 justify-content-between main-card-cont-inner">
                        <div className="card-flex-row" style={{ paddingRight: '7px', paddingLeft: '10px' }}>
                            <div className="card-offer-flexbox px-1 py-0">
                                <div className="px-1 card-offer-item text-center">
                                    <a href="#">
                                        <img className="img-fluid" src={Images.offers}
                                            alt="anImg" />
                                        <p className="mb-0 offers-cr-text text-center">Offers</p>
                                    </a>
                                </div>
                                <div className="my-2 mx-0 vertical-hr"></div>
                                <div className="px-1 card-offer-item text-center">
                                    <a href="#">
                                        <img className="img-fluid" style={{ paddingBottom: '2px' }} src={Images.stars}
                                            alt="anImg" />
                                        <p className="mb-0 mt-1 offers-cr-text text-center">Stars</p>
                                    </a>
                                </div>
                            </div>

                            {
                                [1,2,3,4,5,6].map((e, i) => (
                                    <div key={`prodCard1${i}`} className="prod-card">
                                        <div className="prod-img position-relative">
                                            <a href="#">
                                                <img className="img-fluid"
                                                    src={Images.prodImg}
                                                    alt="anImg" />
                                                <img className="img-fluid food-dot-img" src={Images.grSquare}
                                                    alt="anImg" />
                                            </a>
                                        </div>
                                        <div className="prod-card-details position-relative">
                                            <p className="cr-prod-name mb-0">Alferdo Pasta</p>
                                            <div className="pt-1 pb-0 d-flex align-items-center">
                                                <span className="cr-bs-dot"></span>
                                                <span className="cr-bs-text">Best Seller</span>
                                                <span className="text-muted cr-bs-dish"> | Pasta</span>
                                            </div>
                                            <div className="prod-rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                            <p className="cr-prod-price mb-0">
                                                <sup>₹</sup>
                                             249
                                        </p>
                                            <button className="cr-add-btn">
                                                {
                                                    itemQuantity[e] && itemQuantity[e] ? (
                                                        <div className="cr-add-btn-active">
                                                            <i className="fas fa-minus" onClick={()=>quantityHandler(e,'dec',itemQuantity)} ></i>
                                                            <span>{itemQuantity[e]}</span>
                                                            <i className="fas fa-plus" onClick={()=>quantityHandler(e,'inc',itemQuantity)} ></i>
                                                        </div>
                                                    ) : (
                                                        <a onClick={()=>quantityHandler(e,'add',itemQuantity)}>
                                                            <img className="img-fluid" src={Images.plus}
                                                                alt="anImg" /> Add</a>
                                                    )
                                                }

                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="card-flex-row pl-1" style={{ paddingRight: '7px', paddingLeft: '10px' }}>
                            {
                                [7,8,9,10,11,12].map((e, i) => (
                                    <div key={`prodCard1${i}`} className="prod-card">
                                        <div className="prod-img position-relative">
                                            <a href="#">
                                                <img className="img-fluid"
                                                    src={Images.prodImg}
                                                    alt="anImg" />
                                                <img className="img-fluid food-dot-img" src={Images.grSquare}
                                                    alt="anImg" />
                                            </a>
                                        </div>
                                        <div className="prod-card-details position-relative">
                                            <p className="cr-prod-name mb-0">Alferdo Pasta</p>
                                            <div className="pt-1 pb-0 d-flex align-items-center">
                                                <span className="cr-bs-dot"></span>
                                                <span className="cr-bs-text">Best Seller</span>
                                                <span className="text-muted cr-bs-dish"> | Pasta</span>
                                            </div>
                                            <div className="prod-rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                            <p className="cr-prod-price mb-0">
                                                <sup>₹</sup>
                                             249
                                        </p>
                                            <button className="cr-add-btn">
                                                {
                                                    itemQuantity[e] && itemQuantity[e] ? (
                                                        <div className="cr-add-btn-active">
                                                            <i className="fas fa-minus" onClick={()=>quantityHandler(e,'dec',itemQuantity)} ></i>
                                                            <span>{itemQuantity[e]}</span>
                                                            <i className="fas fa-plus" onClick={()=>quantityHandler(e,'inc',itemQuantity)} ></i>
                                                        </div>
                                                    ) : (
                                                        <a onClick={()=>quantityHandler(e,'add',itemQuantity)}>
                                                            <img className="img-fluid" src={Images.plus}
                                                                alt="anImg" /> Add</a>
                                                    )
                                                }

                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/* <!-- main card cont ends --> */}

                <div className="fixed-order-btn-cont">
                    <Link to="/prod-details">
                        <div className="fixed-order-btn">
                            <div className="fo-item-details">
                                <p className="fo-item-qty">1 ITEM</p>
                                <p className="fo-item-price">
                                    <sup>₹</sup>249
                                <span>Plus Taxes</span>
                                </p>
                            </div>
                            <div className="fo-view-order">
                                <p className="mb-0">View Order&nbsp;<i className="fas fa-chevron-right" aria-hidden="true"></i> </p>
                            </div>
                        </div>
                    </Link>
                </div>

            </div>
        </div >
    )
}

export default Home;