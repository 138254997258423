import search from './../assets/icons/noun_Search_14173.svg';
import offers from './../assets/icons/shutterstock_1648830712.png';
import storyImg from './../assets/img/fdd7102e4c471f475ae7a442b1ec819d.png';
import stars from './../assets/icons/Group_0516.svg';
import grSquare from './../assets/icons/Group_5481.svg';
import mic from './../assets/icons/Group_10874.svg';
import plus from './../assets/icons/Group_10877.svg';
import share from './../assets/icons/Group 1.svg';
import shareDark from './../assets/icons/Group 1_dark.svg';
import payBillIcon from './../assets/img/pay-bill-img.png';
import topping from './../assets/img/topping.png';
import prodImg from './../assets/img/fdd7102e4c471f475ae7a442b1ec819d@2x.png';
import prodImg1 from './../assets/img/shutterstock_275882204.png';
import prodImg12 from './../assets/img/shutterstock_275882204@2x.png';
import prodImg2 from './../assets/img/shutterstock_594962855@2x.png';
import goBack_alt from './../assets/icons/Group_10880.svg';
import food from './../assets/icons/food(1).svg';
import lunch from './../assets/icons/lunch.svg';
import share_alt from './../assets/icons/Group_10879.svg';
import suggested_dish from './../assets/img/suggested_dish.png';
import credit_card from './../assets/icons/Path 63663.svg';
import amazonPay from './../assets/icons/amazonPay.png';
import phonepe from './../assets/icons/phonepe.png';
import addCircle from './../assets/icons/Group 11073.svg';
import payAtShop from './../assets/icons/Path 63670.svg';
import payAtShopImage from './../assets/img/Group 10872.svg';
import back from './../assets/icons/ic-back.svg';
import searchCircle from './../assets/icons/search_circular.svg';
import payBill_svg from './../assets/icons/paybill-icon.png';

export default {
    search,
    mic,
    storyImg,
    prodImg,
    offers,
    stars,
    grSquare,
    plus,
    share,
    shareDark,
    payBillIcon,
    topping,
    prodImg1,
    prodImg12,
    prodImg2,
    goBack_alt,
    share_alt,
    suggested_dish,
    food,
    lunch,
    credit_card,
    amazonPay,
    phonepe,
    addCircle,
    payAtShop,
    payAtShopImage,
    back,
    searchCircle,
    payBill_svg
}