import './App.css';
import Navigation from './navigation';

function App() {
     return (
          <div className="App">
               <Navigation />
          </div>
     );
}

export default App;
